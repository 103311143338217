import { io } from 'socket.io-client';
import { ref } from 'valtio';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
export class FrLiteChartOfSocketDatafeed {
    constructor(server) {
        this.ioAsRef = {
            current: !server
                ? null
                : ref(io(server.wsUrl || '', {
                    query: {
                        __module: 'FrLiteChartOfSocketDatafeed',
                        uid: useMeStore.getState().meUserState?.uid,
                        agent: fr_agents.agent,
                        product: fr_agents.product,
                    },
                    autoConnect: true,
                    reconnection: true,
                })),
        };
        this.ioAsRef.current?.on('connect', () => {
            debugAPI.liteChart.log(`socket.on('connect')`);
        });
        this.ioAsRef.current?.on('disconnect', () => {
            debugAPI.liteChart.log(`socket.on('disconnect')`);
        });
        this.ioAsRef.current?.on('data', (event) => {
            console.log('!!! 🍏 this.subscribedCallbacks', this.subscribedCallbacks);
            for (const cb of this.subscribedCallbacks) {
                const bars = JSON.parse(event.data);
                cb(bars, event.subscriberUID);
            }
        });
    }
    subscribe(params) {
        debugAPI.liteChart.log(`.subscribe(params)`, params);
        this.socket.emit('subscribe-symbol', {
            symbol: params.symbol,
            resolution: params.interval,
            subscriberUID: `${params.symbol}_#_${params.interval}`,
        });
    }
    unsubscribe(params) {
        debugAPI.liteChart.log(`.unsubscribe(params)`, params);
        this.socket.emit('unsubscribe-symbol', {
            symbol: params.symbol,
            resolution: params.interval,
            subscriberUID: `${params.symbol}_#_${params.interval}`,
        });
    }
    subscribedCallbacks = [];
    ioAsRef;
    get socket() {
        if (!this.ioAsRef.current) {
            throw new Error(`必須先建立 Socket 實體`);
        }
        return this.ioAsRef.current;
    }
}
