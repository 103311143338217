export function barsToSma(data, count = 5) {
    const avg = function (data_) {
        let sum = 0;
        for (let i = 0; i < data_.length; i++) {
            sum += data_[i].close;
        }
        return sum / data_.length;
    };
    const result = [];
    for (let i = count - 1, len = data.length; i < len; i++) {
        const val = avg(data.slice(i - count + 1, i));
        result.push({ time: data[i].time, value: val });
    }
    return result;
}
