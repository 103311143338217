import { findLastIndex } from 'lodash';
/**
 * - 作為 Series 的 datafeed
 * - - 支持 `OHLC 格式` 的 {@link ISeriesApi}
 * - - 支持 `{value, time}` 的 {@link ISeriesApi}
 */
export class FrLiteChartOfKbars {
    /** 圖表上可見的所有K棒資料集合 */
    all = [];
    /**
     * 用於排隊插入或更新最後一根 bar，之後清空
     *
     * 具體用於 `series.update(bar)`
     */
    new = [];
    /**
     * 用於將圖表所有 bars 重設，之後清空
     *
     * 具體用於 `series.setData(bars)`
     */
    forReset = [];
    get hasBarsForReset() {
        return this.forReset.length > 0;
    }
    get hasNewBars() {
        return this.new.length > 0;
    }
    prepareResetBars(bars) {
        this.forReset = [...bars];
        this.all = [...this.forReset];
    }
    prepareNewBars(bars) {
        this.new = [...bars];
        const firstBar = bars.at(0);
        const lastBar = bars.at(-1);
        const lastBarOfAll = this.all.at(-1);
        if (firstBar && lastBar && bars.length && this.all.length > bars.length) {
            const firstBarIndex = findLastIndex(this.all, bar => bar.time === firstBar.time);
            // const lastBarIndex =  findLastIndex(this.kbars, (bar) => bar.time === lastBar.time)
            bars.forEach((bar, index) => {
                this.all[firstBarIndex + index] = bar;
            });
        }
        /** 如果傳入的 bars 太舊；例如缺了一組尾巴的 bar，那就不該被排入更新排程 */
        if (lastBarOfAll && lastBar && lastBarOfAll.time > lastBar.time) {
            this.new = [];
        }
    }
}
