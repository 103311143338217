import { isArray, map } from 'lodash';
import { expectType } from '~/utils/tsd';
/**
 * 我們的後端 bars 轉換到 lightweight-chart 的 Candlestick series
 *
 * 其實就大概差一個 Volume 而已，lightweight-chart 不吃它
 */
// @ts-expect-error 原因：解不出來 infer return type 到底是「 Array 或不是 Array 」（所以要修到沒紅線）
export const barsToBars = bars => {
    if (!isArray(bars)) {
        return {
            close: bars.close,
            value: bars.close,
            high: bars.high,
            low: bars.low,
            open: bars.open,
            time: bars.time,
        };
    }
    return map(bars, bar => {
        return {
            close: bar.close,
            value: bar.close,
            high: bar.high,
            low: bar.low,
            open: bar.open,
            time: bar.time,
        };
    });
};
/* istanbul ignore next */
function TypingTesting() {
    const bar = {};
    /** 可接受 bar[] */
    const multiBars = barsToBars([bar, bar, bar, bar]);
    expectType(multiBars);
    /** 可接受 單個 bar */
    const singleBar = barsToBars(bar);
    expectType(singleBar);
}
